import * as React from "react";

interface Props {
    label?: string | JSX.Element;
    textSize?: "sm";
    autoGrow?: boolean;
}
export const TextArea: React.FC<
    Props & React.TextareaHTMLAttributes<HTMLTextAreaElement>
> = props => {
    const { label, className, textSize, autoGrow, ...rest } = props;

    const handleAutoGrow = (e: React.FormEvent<HTMLTextAreaElement>) => {
        e.currentTarget.parentElement.dataset.replicatedValue = e.currentTarget.value;
    };

    let textSizeClass = "";

    switch (textSize) {
        case "sm":
            textSizeClass = "text-sm";
            break;
    }

    const innerComponent = (
        <textarea
            className={`form-input rounded-sm md:rounded w-full disabled:bg-gray-100 border-gray-300 hover:border-brand-600 active:border-brand-400 focus:ring-2 focus:ring-brand-300 ${textSizeClass}`}
            onInput={autoGrow ? handleAutoGrow : undefined}
            {...rest}
        />
    );

    return (
        <div className={`${className ?? ""} w-full`}>
            <label>
                {label ? <div className="mb-1">{label}</div> : null}
                {autoGrow ? (
                    <div className="grow-wrap" data-replicated-value={rest.value}>
                        {innerComponent}
                    </div>
                ) : (
                    innerComponent
                )}
            </label>
        </div>
    );
};
